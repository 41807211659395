import Nav from "./Nav";
import Headroom from "react-headroom";

function Header() {
    return (
        <header>
            <Headroom>
                <Nav />
            </Headroom>
        </header>
    );
};

export default Header;