//Import necessary modules
import { useState, createContext, useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [ user, setUser ] = useState(null);
    const navigate = useNavigate();

    const submitLogout = () => {
        const url = "https://backend.passion-photography.org/users/logout";
        return fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
           "Content-Type": "application/json",
            },
            body: JSON.stringify({refreshToken : localStorage.getItem("refreshToken")}),
       })
         .then((res) =>
           res.json().then((res) => {
            console.log(localStorage.getItem("refreshToken"));
            console.log(res);
             
           })
         )
         .catch((error) => console.log(error));
      };

    const login = (user) => {
        setUser(user);
        localStorage.setItem("user", user);
    };

    const logout = () => {
        submitLogout();
        setUser(null);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        navigate("/");
    };

    return (
        <AuthContext.Provider value = {{user, login, logout}}>{children}</AuthContext.Provider>
    )
};
    
export const useAuth = () => {
    return useContext(AuthContext);
};