import { Link, useNavigate } from 'react-router-dom'

//CSS
import '../css/Footer.css';

//Logo
import logo from '../Logos/logo-newdimensions.png'

function Footer() {

    const navigate = useNavigate();

    return (
        <footer>
            <div className='test'>
                <div className='container1'>
                    <ul>
                        <li>
                            <img src={logo} onClick={() => navigate('/')}/>
                        </li>
                    </ul>
                </div>
                <div className='container2'>
                    <ul>
                        <li>
                            <Link to="/Contact">Contact</Link>
                        </li>
                        <li>
                            <Link to="/About">About</Link>
                        </li>
                    </ul>
                </div>
                <div className='container3'>
                    <ul>
                        <li>
                            <Link to="/Login">Login</Link>
                        </li>
                        <li>
                            <Link to="/Register">Register</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;