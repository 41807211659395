//Import Neccessary Modules
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Import Css
import '../css/Contact.css';

export default function Contact() {
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmit] = useState(null);
    const navigate = useNavigate();

    //LocalHost Link
    // const url = "http://localhost:4000/contact";

    //AWS Link
    const url = "https://backend.passion-photography.org/contact";


    const submitEnquiry = (event) => {
        event.preventDefault();

        if (!name || !subject || !email || !message){
            window.alert("Not all fields have been entered, please re-submit")
            return;
        };

        let errors = validate();
        if (errors.email){
            window.alert("Registration Failed: " + errors.email)
            //Reset the state value if the email
            setEmail('');
            return;
        };

        let body = {Name: name, Email: email, Subject: subject, Message: message};
        setSubmit(true);

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                window.alert(`Enquiry Failed: ${res.message}`); //Alert the user as to why the registration failed
            }

            else {
                // window.alert(`Thankyou, Your Enquiry Has Been Submitted!`);
                
            }})
            .catch((error) => console.log(error))
    };

    const validate = () => {
        const errors = {}; //Define a dictionary for the errors
        //Regex email validator (format should be name@mailserver.com or another equivelant)
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          errors.email = 'Invalid email address'; //Assign the error
        }
        return errors
    };

    return (
        <div className='contact-container'>
            <div className='contact-heading'>
                <h1>Contact Us</h1>
                <hr className='photos-hr'/>
                <br />
                <p>If you notice any bugs with the website, or have any general enquiries, please leave them below and we will get back to you.</p>
                <br></br>
            </div>
            <div className='contact-heading-info'>
                <h2>Get In Touch</h2>
                <br />
            </div>
            

            {
                !submitted && (
                    <>
                    <div className='contact-form-container'>
                        <form onSubmit={submitEnquiry}> 
                            <input 
                                type='contact-name'
                                placeholder='Name'
                                name='name'
                                id='name'
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}>
                            </input>
                            <input 
                                type="contact-email" 
                                placeholder='Email'
                                name='email'
                                id='email'
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}>
                            </input>
                            <input 
                                type="contact-subject" 
                                placeholder='Subject'
                                name='subject'
                                id='subject'
                                value={subject}
                                onChange={(event) => {
                                    setSubject(event.target.value);
                                }}>
                            </input>
                            <textarea
                                id='message'
                                name='message'
                                placeholder='Message'
                                style={{height: 150, marginLeft: 0, marginBottom: 0}}
                                value={message}
                                onChange={(event) => {
                                    setMessage(event.target.value);
                                }}>
                            </textarea>
                            <div className='submit-container'>
                                <button className='sbutton' type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                    </>
                )
            }

            {
                submitted && (
                    <>
                        <div>
                            <p>Thank you for submitting your enquiry, we will get back to you as soon as we can.</p>
                            <br></br>
                            <p>In the meantime, feel free to browse our gallery</p>
                            <br></br>
                            <br></br>
                            <button className='sbutton' onClick={() => {navigate('/photos')}}>Photo Gallery</button>
                        </div>
                    </>
                )
            }

            
        </div>

        // <div className='contact-container'>
        //     <div className='contact-heading'>
        //         <h1>Oops !</h1>
        //         <br />
        //         <h3>This page is currently underoing maintenace. Please check back soon.</h3>
        //     </div>
        // </div>
    )
};