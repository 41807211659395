
export const sliderData = [
    {
        id: "first",
        image: "/photos/000069.jpg",
        heading: "London",
        desc: "One of Our Favourites From London, England."
    },
    {
        id: "second",
        image: "/photos/000071.jpg",
        heading: "Paris",
        desc: "One of Our Favourites From Paris, France."
    },
    {
        id: "third",
        image: "/photos/000073.jpg",
        heading: "Seville",
        desc: "One of Our Favourites From Seville, Spain."
    },
    {
        id: "fourth",
        image: "/photos/000016.jpg",
        heading: "Lake Como",
        desc: "One of Our Favourites From Lake Como, Italy."
    },
    {
        id: "fifth",
        image: "/photos/000007.jpg",
        heading: "Lake Como",
        desc: "One of Our Favourites From Rome, Italy."
    }
]