//Import necessary modules
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/Authentication"
import { useEffect } from "react";

//Import CSS
import '../css/Logout.css';


export default function Logout() {
    const auth = useAuth();

    useEffect(() => {
        window.scrollTo(0,0);
    });

    function logout_handler() {
      auth.logout(); //user the function in the authentication code
      window.alert("You Have Succesfully Logged Out!")
    };


    return (
        <div className="logout-body">
            <div className="logout-container">
                <div className="logout-heading">
                    <h1>Logout</h1>
                </div>
                <div className="logout-text">
                    <h3 className="logout-h3">Thankyou for visiting</h3>
                    <h3 className="logout-h3">We Hope You Enjoyed Your Experience With Us!</h3>
                    <button className="sbutton" style={{marginTop: 30}}onClick={() => logout_handler()}>Logout</button>
                </div>
                
            </div>
            
        </div>
    )
}
