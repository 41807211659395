import './App.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';

//components
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider } from "./components/Authentication";

//Pages
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Not_Found from './pages/Not_Found';
import About from './pages/About';
import Photos from './pages/Photos';
import Contact from './pages/Contact';
import Logout from './pages/Logout';

function App() {
  return (
    <BrowserRouter>
    <div className='App'>
      <AuthProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Login" element={<Login />} />
        <Route path='/About' element={<About />} />
        <Route path='/Photos' element={<Photos />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Logout' element={<Logout />} />
        <Route path="*" element={<Not_Found />} />
      </Routes>
      <Footer />
      </AuthProvider>
    </div>
    </BrowserRouter>
  );
}

export default App;
