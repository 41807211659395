//Import Neccessary Modules
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";

//Import CSS
import '../css/Photos.css';

export default function Photos() {
    const [imageUrls, setImageUrls] = useState([]);
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});
    const token = localStorage.getItem("token");

    function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	};

    useEffect(() => {
        window.scrollTo(0,0);

        //Localhost Link
        // fetch('http://localhost:4000/aws/all', {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": `Bearer ${token}`
        //     }
        // })

        //AWS Link
        fetch('https://backend.passion-photography.org/aws/all', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
            })
            .then((response) => response.json())
            .then((data) => {
            setImageUrls(data);
            })
            .catch((error) => console.error('Error fetching pre-signed URLs:', error));
        }, []);

    return (
        <div className='photos-body'>
            <FsLightbox
				toggler={lightboxController.toggler}
				// sources={imageUrlArray}
                sources={imageUrls.map((imageUrl) => imageUrl.url)}
                slide={lightboxController.slide}
                type="image"
			/>
            <div className='photos-container'>
                <div className='photos-heading'>
                    <h1>Photo Gallery</h1>
                    <hr className="photos-hr"/>
                    <br />
                    <p>At the moment we only have a small selection of film photos for you to check out. However, come back in the near future to see some new pics !</p>
                </div>
                <div className="gallery">
                {imageUrls.map((imageUrl, index) => (
                    <img key={imageUrl.key} src={imageUrl.url} alt={imageUrl.key} onClick={() => {openLightboxOnSlide(index + 1)}}/>
                ))}
                </div>
            </div>
        </div>
    )
}