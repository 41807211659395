//Import necessary modules
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../components/Authentication";

//CSS
import '../css/Accounts.css';

//Icons
import email_icon from '../Logos/email.png';
import password_icon from '../Logos/password.png';

// function Login() {
//     return (
//         <h1>Login</h1>
//     );
// };

// export default Login;

export default function Login(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = useAuth();
    
    const navigate = useNavigate();
    // const auth = useAuth();

    //LocalHost Link
    // const url = "http://localhost:4000/users/login";

    //AWS Link
    const url = "https://backend.passion-photography.org/users/login";

    const clearForm = () => { //Reset the states
         setEmail('');
         setPassword('');
     }

    //Log the user into the application and API
    const submitLogin = (event) => {
        event.preventDefault(); //So that the form actually works
        clearForm(); //Clear the form

        return fetch(url, {
          method: "POST", 
            headers: {
           "Content-Type": "application/json",
            },
            body: JSON.stringify({email: email, password: password}),
       })
         .then((res) => res.json())
         .then((res) => {           
            console.log(res);

            if (res.error) {
            window.alert(`Login Failed: ${res.message}`); //alert user of the error they encountered
            
            }
            else { //Put authentication tokens into local storage
                localStorage.setItem("token", res.bearerToken.token);
                localStorage.setItem("refreshToken", res.refreshToken.token);
                window.alert(`Login Successful`); //alert user
                navigate("/")
             
            }}
         )
         .catch((error) => console.log(error))
      };

    // useEffect(() => {
    //     window.scrollTo(0,0);
    // });


    return (
        // <div className="body">
        //     <div className="container">
        //         <div className="header">
        //             <div className="text">
        //                 <h1>Login Page</h1>
        //             </div>
        //         </div>
                
        //         <form onSubmit={submitLogin}>
        //             <label htmlFor="email">Please Enter Your Email: </label>
        //             <input
        //                 type="text"
        //                 name="email"
        //                 id="email"
        //                 value={email}
        //                 onChange={(event) => {
        //                     setEmail(event.target.value);
        //                     }
        //                 }
        //             />
                    

        //             <label htmlFor="password">Please Enter Your Password: </label>                
        //             <input
        //                 type="password"
        //                 name="password"
        //                 id="password"
        //                 value={password}
        //                 onChange={(event) => {
        //                     setPassword(event.target.value);
        //                     }
        //                 }
        //             />

        //             <button type="submit">Login</button>
        //             {/* onClick={() => {auth.login(email)}} */}
                    
        //             <br></br>
        //             <br></br>
        //             <p>Don't Have an Account: <button onClick={() => navigate("/Register")}>Register Here!</button></p>
                    
        //         </form>
        //     </div>  
        // </div>
        <div className='body'>
            <div className='container'>
                <div className='header'>
                    <div className='text'>
                        <h1>Login</h1>
                    </div>
                    <div className="underline">
                    </div>
                </div>
                <form onSubmit={submitLogin}>
                    <div className='inputs'>
                        <div className='input'>
                            <img src={email_icon} alt="" />
                            <input 
                                type="email" 
                                placeholder='Email'
                                name="email"
                                id="email"
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    }
                                }>
                            </input>
                        </div>
                        <div className='input'>
                            <img src={password_icon} alt="" />
                            <input 
                                type="password" 
                                placeholder='Password'
                                name="password"
                                id="password"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    }
                                }
                            ></input>
                        </div>
                    </div>
                    <div className='forgot-password'>Forgot Passoword? <span>Click Here</span></div>
                    <div className='forgot-password'>Don't Have an Account? <span onClick={() => {navigate('/Register')}}>Register</span></div>
                    <div className='submit-container'>
                        <button className='sbutton' type="submit" onClick={() => {auth.login(email)}}>Login</button>
                        {/* <button className='sbutton' onClick={() => navigate("/Login")}>Login</button> */}
                    </div>
                </form>
                
                
            </div> 
        </div>
    );
};