// Import Neccessary Libraries
import React, { useEffect, useState } from "react";
import {Link, useNavigate } from "react-router-dom";

//Import Slider
import Slider from "../components/Slider";

//CSS
import '../css/Home.css'

function Home () {
    const navigate = useNavigate();
    

    // const [photo, setPhoto] = useState([]);

    // useEffect(() => {
    //     const url = `http://localhost:4000/photos`;
    //     fetch(url)
    //       .then((res) => res.json())
    //       .then((data) => setPhoto(data))
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }, []);
    // {photo && <img src={`/${photo.path}`} width={100} height={100} alt={`${photo.path}`} />}

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return(
        // <div className="body">
        //     <div className="hero-container">
        //         <div className="hero-img">
        //             <div className="hero-content">
        //                 <div className="hero-text">
        //                     <h1>Welcome to Passion Photography</h1>
        //                 </div>
        //             </div>
        //         </div>               
        //     </div>
        //     <div className="about-container">
        //         <div className="about">
        //             <h1>About Us</h1>
        //         </div>
        //     </div>            
        // </div>
        // <Slider>
        //     <h1>Hello</h1>
        // </Slider>


        <div className="home-body">
            
            <Slider />

            <div className="home-container">
                <h1>Our Aim</h1>
                <hr className="home-hr"/>
                <div className='home-container-text'>
                    <br />
                    <p>The aim of this site is to share the amazing pictures from our travels with family, friends, and anyone else who may be interested.</p>
                    <br />
                    <p>To get started, navigate to the 'photos' page and start browsing, alternatively, you can create an account to access our 'private' photos.</p>
                    <br />
                    <p>If you want to learn more about us, click the button below. There are also some helpful hints in the below section that you might want to check out while you're here as well!</p>
                </div>
                <div className="submit-container">
                    <button className="sbutton" onClick={() => {navigate('/About')}}>Learn More About Us!</button>
                </div>
            </div>

            <div className="home-divider">
                <div home-divider-container>
                    <div className="divider-img">
                        <img src="/photos/000036.jpg" style={{width: "1499px", height: "756px"}}></img>
                        <div className="divider-content">
                            <h1 style={{paddingBottom: "3rem"}}>Want to See More of Our Photos ?</h1>
                            <button className="sbutton" onClick={() => {navigate('/Photos')}}>Click Here !</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-container">
                <h1>Helpful Tips</h1>
                <hr className="home-hr"/>
                <div className="home-container-text">
                    <p>To get the most out of this site, an account is needed. To protect the privacy of both Zoe and myself, access to the photos with us in them is restricted to users which have an account with us.</p>
                    <br />
                    <p>To register for your account, remember to add the 'secret code', otherwise you won't be allowed to register.</p>
                    <br />
                    <p>While this is the case, there are still plenty of amazing photos from our trips available to everyone, so don't be discouraged if you are not allowed to create an account.</p>
                    <br />
                    <p>If you have any troubles with the registration process, please contact us.</p>
                </div>
                <div className="submit-container">
                    <button className="submit-container-sbutton" onClick={() => {navigate('/Register')}}>Create an Account</button>
                    <button className="submit-container-sbutton" onClick={() => {navigate('/Login')}}>Login</button>
                </div>
            </div>
        </div>


        
    );
};

export default Home;