import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//CSS
import '../css/Accounts.css'

//Icons
import user_icon from '../Logos/person.png';
import email_icon from '../Logos/email.png';
import password_icon from '../Logos/password.png';

export default function Register(){
    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [secretCode, setSecretCode] = useState('');

    const navigate = useNavigate();

    //LocalHost Link
    // const url = "http://localhost:4000/users/register";

    //AWS Link
    const url = "https://backend.passion-photography.org/users/register";
    const correctCode = "x6_Gw76e,y";

    //Send account details to the API so that the account can be created
    const submitRegistration = (event) => {
        event.preventDefault(); //So that the form actually works
        // clearForm();
        
        //If the passwords dont match
        if (password !== confirmPassword) {
            window.alert("Registration Failed: Passwords Do Not Match");

            //Reset the state value of the passwords
            setPassword('');
            setConfirmPassword('');
            
            return;
        };

        //If the email does not follow the correct format
        let errors = validate();
        if (errors.email){
            window.alert("Registration Failed: " + errors.email)
            
            //Reset the state value if the email
            setEmail('');

            return;
            
        };

        //If a secret code has been entered
        let body = {firstname: firstName, surname: surName, email: email, password: password}
        if (secretCode) {
            body = {firstname: firstName, surname: surName, email: email, password: password, secret: secretCode}
        };


        //If the details are valid, send them to the API
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                window.alert(`Registration Failed: ${res.message}`); //Alert the user as to why the registration failed
                
                if (res.message === "Secret Key is Incorrect - Please Try Again") {
                    setSecretCode('');
                };
            }

            else {
                navigate("/Login");
                window.alert(`Registration Successful: ${res.message}`);
            }})
            .catch((error) => console.log(error))
    }

    //Validates that the user email is in the correct format
    const validate = () => {
        const errors = {}; //Define a dictionary for the errors
        //Regex email validator (format should be name@mailserver.com or another equivelant)
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          errors.email = 'Invalid email address'; //Assign the error
        }
        return errors
    }

    // const clearForm = () => {
    //     setFirstName('');
    //     setSurName('');
    //     setPassword('');
    //     setConfirmPassword('');
    //     setSecretCode('');
    // }

    // useEffect(() => {
    //     window.scrollTo(0,0);
    // });


    return (
        <div className="body">
            <div className='container'>
                <div className='header'>
                    <div className='text'>
                        <h1>Registration</h1>
                        <br />
                        <h4>"*" Required Information</h4>
                    </div>
                    <div className='underline'></div>
                </div>
                <form onSubmit={submitRegistration}>
                    <div className='inputs'>
                        <div className='input'>
                            <img src={user_icon} alt="" />
                            <input 
                                type="text" 
                                placeholder='* First Name'
                                name='firstName'
                                id='firstName'
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value);
                                }}>
                                </input>
                        </div>
                        <div className='input'>
                            <img src={user_icon} alt="" />
                            <input 
                                type="text" 
                                placeholder='* Surname'
                                name='surName'
                                id='surName'
                                value={surName}
                                onChange={(event) => {
                                    setSurName(event.target.value);
                                }}>
                            </input>
                        </div>
                        <div className='input'>
                            <img src={email_icon} alt="" />
                            <input 
                                type="email" 
                                placeholder='* Email'
                                name='email'
                                id='email'
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}>
                            </input>
                        </div>
                        <div className='input'>
                            <img src={password_icon} alt="" />
                            <input 
                                type="password" 
                                placeholder='* Password'
                                name='password'
                                id='password'
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}>
                            </input>
                        </div>
                        {/* <div className='forgot-password'>Note -  Password must be </div> */}
                        <div className='input'>
                            <img src={password_icon} alt="" />
                            <input 
                                type="password" 
                                placeholder='* Confirm Password'
                                name='confirmPassword'
                                id='confirmPassword'
                                value={confirmPassword}
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value);
                                }}>
                            </input>
                        </div>
                        <div className='input'>
                            <img src={password_icon} alt="" />
                            <input 
                                type="text" 
                                placeholder='* Secret Code'
                                name='secretCode'
                                id='secretCode'
                                value={secretCode}
                                onChange={(event) => {
                                    setSecretCode(event.target.value);
                                }}>
                            </input>
                        </div>
                    </div>
                    <div className='forgot-password'>Already Have an Account? <span onClick={() => {navigate('/Login')}}>Login</span></div>
                    <div className='forgot-password'>Don't Want an Account? <span onClick={() => {navigate('/')}}>Click Here</span></div>
                    <div className='submit-container'>
                        <button className='sbutton' type="submit">Register</button>
                        {/* <button className='sbutton' onClick={() => navigate("/Login")}>Login</button> */}
                    </div>
                </form>
            </div>
        </div>
    );
};