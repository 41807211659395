//Import Necessary Libraries
import { useEffect,  } from 'react';
import { useNavigate } from 'react-router-dom';


//import CSS
import '../css/About.css';

//import images
import logo from '../Logos/black-logo-resize.png';


export default function About() {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div className='about-body'>
            <div className='about-cont'>
                <div className='about-heading'>
                    <h1>About Us</h1>
                    <hr className='photos-hr'/>
                    <div className='heading-img'>
                        <img src={logo}></img>
                    </div>
                </div>
                <div className='about-text'>
                    {/* <p>
                        I'm passionate about the endless possibilities and transformative power of artificial intelligence. AI has the potential to revolutionize how we approach and solve problems across various domains, from healthcare and education to business and research.
                    </p>
                    <br></br>
                    <p>
                        Imagine a world where AI-driven technologies enhance our daily lives, making routine tasks more efficient and allowing us to focus on more creative and meaningful endeavors. From advanced medical diagnostics to personalized learning experiences, AI has the capacity to push the boundaries of what we thought was possible.
                    </p>
                    <br></br>
                    <p>
                        The ethical considerations surrounding AI development are also a significant aspect of my passion. It's crucial to ensure that as we harness the potential of AI, we do so responsibly, taking into account issues such as bias, transparency, and the impact on employment.
                    </p>
                    <br></br>
                    <p>
                        As AI continues to evolve, there's an exciting journey ahead, with opportunities for collaboration between humans and machines, opening new frontiers in innovation. The fusion of human ingenuity and artificial intelligence holds the promise of addressing some of the most pressing challenges of our time.
                    </p>
                    <br></br>
                    <p>
                        In the grand tapestry of technological progress, AI stands out as a thread that weaves through diverse fields, connecting ideas and possibilities. It's not just about machines learning; it's about empowering humanity to achieve new heights of creativity, understanding, and collaboration. The journey into the future with AI is a thrilling adventure, and I'm enthusiastic about the positive changes it can bring to our world.
                    </p> */}
                    <p>The aim of this site is to share the amazing pictures from our travels with family, friends, and anyone else who may be interested.</p>
                    <br />
                    <p>Any photos which appear on this site have either been taken by myself or Zoe, on a trip or holiday that we have been on.</p>
                    <br />
                    <p>The inspiration for creating this digital 'catalogue' of sorts for our trip photos is to share some of these incredible experiences. Additionally, the site showcases some of my skills in relation to frontend and backend web application development.</p>
                    <br />
                    <p>We hope that you thoroughly enjoy your experiences with this site, and if you have any questions or concerns dont hesistate to contact us !</p>
                    <br />
                    <button className='sbutton' style={{marginTop: '1.5rem'}} onClick={() => {navigate('/Contact')}}>Contact Us</button>
                </div>
            </div>
        </div>
    )

}