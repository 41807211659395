//Import Neccessary Libraries
import {React, useState, useEffect} from "react";
import {AiOutlineArrowDown, AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

//Import CSS
import '../css/Slider.css'

//Import Slider Data
import { sliderData } from "./Slider-Data";


export default function Slider() {

    const navigate = useNavigate();

    const [currentSlide, setCurrentSlide] = useState(0);
    const slideLength = sliderData.length;

    const autoScroll = true;
    let slideInterval;
    let intervalTime = 7500;

    const nextSlide = () => {
        setCurrentSlide(currentSlide === slideLength -1 ? 0 : currentSlide + 1);
    };

    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide -1);
    };

    function auto() {
        slideInterval = setInterval(nextSlide, intervalTime);
    }

    useEffect(() => {
        setCurrentSlide(0);
    },[]);

    useEffect(() => {
        if (autoScroll) {
            auto();
        };

        return () => {
            clearInterval(slideInterval)
        }
    },[currentSlide]);

    
    return (
        <div className="slider">          
            <AiOutlineArrowLeft className="arrow prev" onClick={prevSlide}/>
            <AiOutlineArrowRight className="arrow next" onClick={nextSlide}/>
            {/* <AiOutlineArrowDown className="arrow-down" onClick={() => {window.scrollTo(900,900)}}/> */}

    
            {sliderData.map((slide, index) => {
                return (
                    <div className={index === currentSlide ? "slide current" : "slide"} key={index}>
                        {index === currentSlide && (
                            <>
                                <img src={slide.image} alt={slide.heading} />
                                <div className="content">
                                    <h2>{slide.heading}</h2>
                                    <p>{slide.desc}</p>
                                    <hr />
                                </div>
                            </>
                        )}
                    </div>
                )
            })}

            <div className="one">
                <h1>Welcome to Passion Photography</h1>
            </div>

            <p className="ppp">Scroll Down to Read More</p>
            <AiOutlineArrowDown className="arrow-down" onClick={() => {window.scrollTo({top: 900, behavior: 'smooth'})}}/>
        </div>
    )
    
};
