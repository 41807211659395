import {Link, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from "react";
import Hamburger from 'hamburger-react';

import { useAuth } from "./Authentication";

//CSS
import '../css/Nav.css';

//Logo
import logo from '../Logos/logo-newdimensions.png';

//Grid
function Nav() {
    const auth = useAuth();

    const [toggleMenu, setToggleMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const ToggleNav = () => {
        setToggleMenu(!toggleMenu); 
    };

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', changeWidth);

        return () => {
            window.removeEventListener('resize', changeWidth);
        };
    }, []);
    
    return (
        <nav className='navvywavvy' onClick={ToggleNav}> 
            {(toggleMenu || screenWidth > 1050) && (
                <>
                    <div className="home">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                        </ul>
                    </div>
                        
                    <div className="photos">
                        <ul>
                            <li>
                                <Link to="/Photos">Photos</Link>
                                <div className="dropdown">
                                    <ul>
                                        <li className="Euro2023"><Link to="/">Europe 2023</Link></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="logo">
                        <Link to="/"><img src={logo}></img></Link>
                    </div>

                    <div className="contact">
                        <ul>
                            <li><Link to="/Contact">Contact</Link></li>
                        </ul>
                    </div>

                    <div className="about">
                        <ul>
                            <li><Link to="/About">About</Link></li>
                        </ul>
                    </div>

                    
                    {
                        // If the user is not logged in to the site, display the following navigation elements
                        !auth.user && (
                        <>
                        <div className="register">
                            <ul>
                                <li><Link to="/Register">Register</Link></li>
                            </ul>
                        </div>

                        <div className='login'>
                            <ul>
                                <li><Link to="/Login">Login</Link></li>
                            </ul>
                        </div>
                        </>
                        )
                    }

                    {
                        //If the user is logged in
                        auth.user && (
                            <>
                            <div className='login'>
                                <ul>
                                    <li><Link to="/Logout">Logout</Link></li>
                                </ul>
                            </div>
                            </>
                        )
                    }
                </>
            )}
            
            <div className="responsive-logo">
                <Link to="/"><img src={logo}></img></Link>
            </div>

            <div className='responsive-button'>
                <Hamburger toggled={toggleMenu} onToggle={ToggleNav}></Hamburger>
            </div>
        </nav>
    );
};

export default Nav;

