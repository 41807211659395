import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

//CSS Page Styling
import "../css/Not_Found.css";

function Not_Found() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div className="NF">
            <div className="elements">
                <h1>Hmmmm, Sorry</h1>
                <h2>That Page Doesn't Exist</h2>
                <button className="return" onClick={() => navigate("/")}>Return Home</button>
            </div>
        </div>
    );
};

export default Not_Found;